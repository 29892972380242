var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "vx-row" }, [
      _c(
        "div",
        { staticClass: "vx-col w-9/12" },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { label: "" },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.enterPressed.apply(null, arguments)
              },
            },
            model: {
              value: _vm.query,
              callback: function ($$v) {
                _vm.query = $$v
              },
              expression: "query",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-3/12" },
        [
          _c(
            "vs-button",
            {
              attrs: { type: "border", color: "primary" },
              on: { click: _vm.newSearch },
            },
            [_vm._v(_vm._s(_vm.$t("buscar")))]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.images.length > 0,
            expression: "images.length > 0",
          },
        ],
        ref: "imageListRef",
        staticClass: "vx-row",
      },
      [
        _c(
          "div",
          { staticClass: "vx-col w-full mr-4" },
          _vm._l(_vm.chunkedImages, function (imgs) {
            return _c(
              "div",
              { key: imgs, staticClass: "grid grid-cols-3 gap-4 mt-4" },
              _vm._l(imgs, function (img) {
                return _c(
                  "vs-row",
                  { key: img },
                  [
                    _c(
                      "vs-col",
                      {
                        staticClass: "w-full",
                        attrs: {
                          "vs-type": "flex",
                          "vs-justify": "center",
                          "vs-align": "left",
                          "vs-w": "1",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { cursor: "pointer" },
                          attrs: { src: img.image.thumbnailLink },
                          on: {
                            click: function ($event) {
                              return _vm.imageSelected(img)
                            },
                          },
                        }),
                      ]
                    ),
                  ],
                  1
                )
              }),
              1
            )
          }),
          0
        ),
        _c(
          "vs-row",
          [
            _c(
              "vs-col",
              {
                staticClass: "w-full mt-6",
                attrs: {
                  "vs-type": "flex",
                  "vs-justify": "center",
                  "vs-align": "left",
                  "vs-w": "1",
                },
              },
              [
                _c(
                  "vs-button",
                  {
                    attrs: {
                      type: "filled",
                      color: "primary",
                      disabled: _vm.disableLoadMore,
                    },
                    on: { click: _vm.loadMore },
                  },
                  [_vm._v(_vm._s(_vm.$t("carregar-mais")))]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }